export default {
  group: [
    {
      name: 'certification_agency_data',
      fields: [
        {
          name: 'full_name',
          type: 'text',
          mask: null,
          column_type: 'full-row',
        },
        {
          name: 'edrpou',
          type: 'text',
          mask: null,
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'contact_data',
      fields: [
        {
          name: 'phone',
          type: 'text',
          mask: 'phone',
          column_type: 'one-third-row',
        },
        {
          name: 'working_email',
          type: 'text',
          mask: 'email',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
