<template>
  <div class="">
    <h4 class="title-h4 profile__title">
      {{ $t('profile.credentians') }}
    </h4>
    <div class="profile__grid">
      <div class="one-third-row">
        <text-field
          v-model="email"
          placeholder="-"
          :title="$t('profile.login')"
          value-mask="email"
          isRequired
          :is-error="$v.email.$error"
        />
      </div>
      <div class="one-third-row">
        <text-field
          v-model="password"
          placeholder="-"
          :title="$t('profile.password')"
          isRequired
          :errorText="getPasswordErrorText"
          :is-error="$v.password.$error"
        />
      </div>
      <div class="one-third-row">
        <text-field
          v-model="password_repeat"
          placeholder="-"
          :title="$t('profile.password_repeat')"
          isRequired
          :errorText="getPasswordErrorText"
          :is-error="$v.password_repeat.$error"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import validate from '@/mixins/createUserByAccessAdminvalidationMixin';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
export default {
  // mixins: [validate],

  props: {
    initValidation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
      password: '',
      password_repeat: '',
      password_error_text: '',
    };
  },

  watch: {
    initValidation(val) {
      this.$v.$touch();
      this.$emit('resetValid');
      if (
        !this.$v.email.$error &&
        !this.$v.password.$error &&
        !this.$v.password_repeat.$error
      ) {
        this.$emit('credentials', {
          email: this.email,
          password: this.password,
        });
      }
    },
  },

  computed: {
    isPasswordsMatched() {
      return this.$v.password_repeat.sameAsPassword;
    },

    isPasswordValidLength() {
      return this.$v.password.minLength;
    },

    getPasswordErrorText() {
      let res = '';
      if (!this.isPasswordValidLength) {
        res = this.$t('error.password_is_short');
      }
      if (!this.isPasswordsMatched) {
        res = this.$t('error.passwords_not_match');
      }
      return res;
    },
  },

  validations: {
    email: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    password_repeat: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style></style>
