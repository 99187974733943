import {
  required,
  requiredIf,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

export default {
  validations() {

    return {
      userData: {
        full_name: {
          required,
        },
        working_email: {
          required,
        },
        phone: {
          required,
        },
        edrpou: {
          required,
        },
      },
    };
  },
};
